import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import {Icon} from '@types';

const Logo : Icon = ({
  fill = "white"
}) => {
  return (
    <LogoContainer>
      <svg
        height={23}
        viewBox="0 0 190.889 114.766"
        width={38}
        className="Logo__Desktop">
        <defs>
          <path id="prefix__a" d="M123.8 121.56h-4.28l2.14-4.29z"/>
          <path id="prefix__b" d="M119.52 121.56h4.28v.01l2.14 4.27h-4.29v-.01z"/>
          <path id="prefix__c" d="M121.66 125.84l-2.14 4.29h-4.29l2.14-4.29z"/>
          <path id="prefix__d" d="M121.66 125.84h-4.29l2.15-4.28z"/>
          <path id="prefix__e" d="M128.08 130.13h-4.29l2.14-4.28v-.01h4.28l-2.14 4.28z"/>
          <path id="prefix__f" d="M130.21 125.84h-4.28l2.14-4.28z"/>
          <path id="prefix__g" d="M134.48 125.84h-4.29l-2.14-4.28h4.28z"/>
          <path id="prefix__h" d="M132.33 121.56h-4.28l2.14-4.29z"/>
          <path id="prefix__i" d="M130.19 125.84h4.29l-2.15 4.29z"/>
          <path id="prefix__j" d="M136.62 130.13h-4.29l2.15-4.29z"/>
          <path id="prefix__k" d="M121.65 125.84h4.29l-2.15 4.29z"/>
        </defs>
        <g transform="matrix(8.92424 0 0 8.92424 -1028.34 -1046.545)">
          <use fill={fill} height="100%" width="100%" xlinkHref="#prefix__a"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__a"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__b"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__b"/>
          <use fill={fill} height="100%" width="100%" xlinkHref="#prefix__c"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__c"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__d"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__d"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__e"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__e"/>
          <use fill={fill} height="100%" width="100%" xlinkHref="#prefix__f"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__f"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__g"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__g"/>
          <use
            fill={fill}
            fillOpacity={0.4}
            height="100%"
            width="100%"
            xlinkHref="#prefix__h"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__h"/>
          <use fill={fill} height="100%" width="100%" xlinkHref="#prefix__i"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__i"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__j"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__j"/>
          <use
            fill={fill}
            fillOpacity={0.4}
            height="100%"
            width="100%"
            xlinkHref="#prefix__k"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__k"/>
        </g>
      </svg>

      <svg
        height={23}
        viewBox="0 0 190.889 114.766"
        width={38}
        className="Logo__Mobile">
        <defs>
          <path id="prefix__a" d="M123.8 121.56h-4.28l2.14-4.29z"/>
          <path id="prefix__b" d="M119.52 121.56h4.28v.01l2.14 4.27h-4.29v-.01z"/>
          <path id="prefix__c" d="M121.66 125.84l-2.14 4.29h-4.29l2.14-4.29z"/>
          <path id="prefix__d" d="M121.66 125.84h-4.29l2.15-4.28z"/>
          <path id="prefix__e" d="M128.08 130.13h-4.29l2.14-4.28v-.01h4.28l-2.14 4.28z"/>
          <path id="prefix__f" d="M130.21 125.84h-4.28l2.14-4.28z"/>
          <path id="prefix__g" d="M134.48 125.84h-4.29l-2.14-4.28h4.28z"/>
          <path id="prefix__h" d="M132.33 121.56h-4.28l2.14-4.29z"/>
          <path id="prefix__i" d="M130.19 125.84h4.29l-2.15 4.29z"/>
          <path id="prefix__j" d="M136.62 130.13h-4.29l2.15-4.29z"/>
          <path id="prefix__k" d="M121.65 125.84h4.29l-2.15 4.29z"/>
        </defs>
        <g transform="matrix(8.92424 0 0 8.92424 -1028.34 -1046.545)">
          <use fill={fill} height="100%" width="100%" xlinkHref="#prefix__a"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__a"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__b"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__b"/>
          <use fill={fill} height="100%" width="100%" xlinkHref="#prefix__c"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__c"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__d"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__d"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__e"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__e"/>
          <use fill={fill} height="100%" width="100%" xlinkHref="#prefix__f"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__f"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__g"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__g"/>
          <use
            fill={fill}
            fillOpacity={0.4}
            height="100%"
            width="100%"
            xlinkHref="#prefix__h"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__h"/>
          <use fill={fill} height="100%" width="100%" xlinkHref="#prefix__i"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__i"/>
          <use
            fill={fill}
            fillOpacity={0.6}
            height="100%"
            width="100%"
            xlinkHref="#prefix__j"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__j"/>
          <use
            fill={fill}
            fillOpacity={0.4}
            height="100%"
            width="100%"
            xlinkHref="#prefix__k"/>
          <use fill="none" height="100%" width="100%" xlinkHref="#prefix__k"/>
        </g>
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div `
  .Logo__Mobile {
    display: none;
  }
  ${mediaqueries.tablet `
    .Logo__Desktop {
      display: none;
    }

    .Logo__Mobile{
      display: block;
    }
  `}
`;
